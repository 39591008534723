












































































































import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";
import { appName, apiUrl } from "@/env";
import { readLoginError } from "@/store/main/getters";
import {
  dispatchLogIn,
  dispatchUserLogOut,
  dispatchUserTokenLogin,
} from "@/store/main/actions";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import _ from "lodash";
import Loading from "./Loading.vue";
import Social from "./Social.vue";

@Component({
  components: { Header, Footer, Loading, Social },
})
export default class Login extends Vue {
  public email: string = "";
  public password: string = "";
  public appName = appName;

  public showLoading = false;
  public showExpired = true;

  public async mounted() {
    const token = _.get(this.$route, "query.token", "");
    if (token) {
      this.showLoading = true;
      await dispatchUserTokenLogin(this.$store, {
        token: token,
      });
    } else {
      await dispatchUserLogOut(this.$store);
      this.showLoading = false;
    }
    if (this.$route.query.referral_cid) {
      this.$cookies.config("999d");
      this.$cookies.set("referral_cid", this.$route.query.referral_cid);
    }
  }
  public shouldShow() {
    return !(
      apiUrl.startsWith("https://dev-op") || apiUrl.startsWith("https://op")
    );
  }

  public get loginError() {
    this.showLoading = false;
    return readLoginError(this.$store);
  }

  public async submit() {
    this.showExpired = false;
    this.showLoading = true;
    await dispatchLogIn(this.$store, {
      username: this.email,
      password: this.password,
    });
    this.showLoading = false;
  }
}
